
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useOrders from "@/composables/useOrders";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  GetOrderDetailForViewDto,
  IGetOrderForViewDto,
  OrderDto,
  OrderStatus,
  PaymentMethod,
  PaymentStatus,
} from "@/shared/service-proxies/service-proxies";
import { formatCurrency, formatDateTime } from "@/core/services/HelperServices";

export default defineComponent({
  setup() {
    const { order, getOrder } = useOrders();
    const route = useRoute();
    const router = useRouter();
    const breadcrumbs = {
      title: "Order",
      breadcrumbs: ["Order", "details"],
    };

    let modelValue = ref<IGetOrderForViewDto>({
      order: {
        orderTotalPrice: 0,
        orderDate: new Date(),
        orderNumber: 0,
        orderStatus: 0 as unknown as OrderStatus,
        orderPaymentStatus: 0 as unknown as PaymentStatus,
        orderPaymentMethod: 0 as unknown as PaymentMethod,
        orderTotalQuantity: 0,
        validationCode: 0,
        isValidated: false,
        agentId: "",
        customerId: "",
        deliveryAgentId: "",
        id: "",
      } as unknown as OrderDto,
      agentFirstname: "",
      customerFirstName: "",
      orderChannel: "",
      orderDetails: [] as unknown as GetOrderDetailForViewDto[],
      deliveryAgentName: "",
    } as unknown as IGetOrderForViewDto);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Order Details", ["Order", "details"]);

      await getOrder(route.params.id as string);

      modelValue.value = order.value as unknown as IGetOrderForViewDto;
    });

    return {
      formatCurrency,
      formatDateTime,
      router,
      modelValue,
      breadcrumbs,
    };
  },
});
